import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getApp } from "firebase/app";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";

const App = ({ children, context }) => {
  const [project, setProject] = useState(null);

  const [tickets, setTickets] = useState([]);

  const location = useLocation();

  useEffect(() => {
    let unsubscribeProjectListener = null;

    if (["/activities"].includes(location.pathname)) {
      const app = getApp();

      if (app && context.project) {
        const firestore = getFirestore(app);

        unsubscribeProjectListener = onSnapshot(
          doc(firestore, "projects", context.project),
          (doc) => {
            setProject({ id: context.project, ...doc.data() });
          }
        );
      }
    }

    return () => {
      if (unsubscribeProjectListener) unsubscribeProjectListener();
    };
  }, [context, location]);

  useEffect(() => {
    let unsubscribeTicketsListener = null;

    if (["/activities"].includes(location.pathname)) {
      const app = getApp();

      if (app && context.project) {
        const firestore = getFirestore(app);

        unsubscribeTicketsListener = onSnapshot(
          query(
            collection(firestore, "tickets"),
            where("projectID", "==", context.project)
          ),
          (snap) => {
            const newDocs = snap.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .filter((i) => !!i.thread);

            setTickets(newDocs);

            window.parent.postMessage(
              { action: "render-dots", tickets: newDocs },
              "*"
            );
          }
        );
      }
    }

    return () => {
      if (unsubscribeTicketsListener) {
        unsubscribeTicketsListener();

        window.parent.postMessage({ action: "render-dots", tickets: [] }, "*");
      }
    };
  }, [context, location]);

  return <>{children({ project, context, tickets })}</>;
};

App.propTypes = {
  children: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

export default App;
